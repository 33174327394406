import React from "react";
import { v4 as uuid } from 'uuid';
import {Form, FormGroup, Col, Button, Alert, Spinner, Row} from 'react-bootstrap';

import LeadSerializer from '../serializers/LeadSerializer';

export default class LeadForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      projectName: '',
      description: '',
      validated: false,
      requestSent: false,
      requestReceived: false,
      requestSucceeded: false,
    };

    this.setValidated = this.setValidated.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendLead = this.sendLead.bind(this);
    this.render = this.render.bind(this);
  }

  setValidated(val) {
    this.setState({
      validated: val,
    })
  }

  changeFormField(field, val) {
    this.setState({...this.state, [field]: val});
  }

  sendLead() {
    this.setState({
      requestSent: true,
    });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(LeadSerializer.serialize({ ...this.state, id: uuid() })),
    };

    const component = this;

    fetch('/api/lead', requestOptions).then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    }).then(function(response) {
      component.setState({
        requestReceived: true,
        requestSucceeded: true,
      });
    }).catch(function(error) {
      component.setState({
        requestReceived: true,
        requestSucceeded: false,
      });
    });
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.setValidated(true);

    if (form.checkValidity() === false || this.state.requestSent) {
      event.stopPropagation();
    } else {
      this.sendLead();
    }
  };

  render() {
    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        <Form.Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Control type={'text'} placeholder={'John Example'} value={this.state.name} onChange={(e) => this.changeFormField('name', e.target.value)} required/>
              <Form.Control.Feedback type="invalid">
                Please fill in your name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Control type={'text'} placeholder={'Cool Project Name'} value={this.state.projectName} onChange={(e) => this.changeFormField('projectName', e.target.value)} required />
              <Form.Control.Feedback type='invalid'>
                Please fill in the project name. You can type Stealth if you don't want to expose name at this point in time.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Control type={'email'} placeholder={'john@example.com'} value={this.state.email} onChange={(e) => this.changeFormField('email', e.target.value)} required/>
              <Form.Control.Feedback type="invalid">
                Please fill in your e-mail.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <FormGroup>
              <Form.Control as={'textarea'} rows={4}
                            placeholder={'Description of the project - tell us more about your business!'} onChange={(e) => this.changeFormField('description', e.target.value)} value={this.state.description} required/>
              <Form.Control.Feedback type="invalid">
                Please describe the project briefly at least, so we can get a basic understanding of the idea.
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={12}>
            <FormGroup>
              <Button id={"lead-btn-request-estimation"} size={'lg'} type="submit" variant={'warning'} className={(this.state.requestSent ? "hidden" : "")} block>Get an estimation</Button>
              <Alert variant={'primary'} className={(this.state.requestSent && !this.state.requestSucceeded && !this.state.requestReceived) ? '' : 'd-none'}>
                <Row>
                  <Col sm={2}>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">
                        Sending...
                      </span>
                    </Spinner>
                  </Col>
                  <Col sm={10}>
                    We are currently sending the message!
                  </Col>
                </Row>
              </Alert>
              <Alert variant={'success'} className={(this.state.requestSent && this.state.requestSucceeded && this.state.requestReceived) ? '' : 'd-none'}>
                <Row>
                  <Col>
                    Your message was received by us. We will contact you shortly!
                  </Col>
                </Row>
              </Alert>
              <Alert variant={'danger'} className={(this.state.requestSent && !this.state.requestSucceeded && this.state.requestReceived) ? '' : 'd-none'}>
                <Row>
                  <Col>
                    There was an error while sending the message :-( try reaching manually to <a href={'mailto:us@electricsheepstud.io'}>us@electricsheepstud.io</a>
                  </Col>
                </Row>
              </Alert>
            </FormGroup>
          </Col>
        </Form.Row>
      </Form>
    )
  }
}
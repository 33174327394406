import React from "react";
import {Container, Row} from "react-bootstrap";

import '../assets/styles/footer.scss';

export default () => {
  return (
    <Container className={'footer'}>
      <Row>
        <hr />
      </Row>
      <Row>
        <Container className={'justify-content-md-right'}>
          <p className={'small'}>
            © 2017-{new Date().getFullYear()} Electric Sheep Studio
          </p>
          <p className={'small'}>
             TAX ID PL9512449469
          </p>
        </Container>
      </Row>
    </Container>
  );
};
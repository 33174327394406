import React from 'react';
import { Jumbotron, Row, Col, Container } from 'react-bootstrap';
import { TiBrush } from 'react-icons/all';

export default () => {
  return (
    <Jumbotron className={'design-section'}>
      <Row>
        <Col xs={12}>
          <h1><TiBrush /> Design</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} />
        <Col xs={12} md={8}>
          <Container>
            <p>Design is important. This is why we are connected to experts that will ensure your business has the perfect interface and user experience.</p>
            <p>With years of experience, and by working closely with UX professionals, we can offer cost effective solutions that will help you convert.</p>
          </Container>
        </Col>
      </Row>
    </Jumbotron>
  )
}
import React from "react";
import {Col, Navbar, Row} from "react-bootstrap";

export default () => {
  return (
    <Navbar bg="light" variant="light">
      <Navbar.Brand href="#home">
        <Row>
          <Col>
            <div className="align-middle bg"/>
          </Col>
          <Col>
            <div className="align-middle lightning flashit"/> { ' ' }
          </Col>
          <Col>Electric Sheep Studio</Col>
        </Row>
      </Navbar.Brand>
    </Navbar>
  )
}
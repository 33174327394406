import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FaCogs, FaRegLightbulb, TiDocumentText} from "react-icons/all";

export default () => {
  return (
    <Container className={'services-section'}>
      <Row className={'section-title justify-content-md-center'}><h1>Services</h1></Row>
      <hr />
      <Row>
        <Col sm={12} md={4}>
          <Row><h2><TiDocumentText /> Management</h2></Row>
          <Row>
            <p>We have experience with big and small projects. In order to properly manage our work, we will work out an estimation for you and plan the scope. During the development we will both tackle new issues and ensure transparency of the process.</p>
          </Row>
        </Col>
        <Col sm={12} md={4}>
          <Row><h2><FaRegLightbulb /> Frontend</h2></Row>
          <Row>
            <p>Our programmers will provide the best front-end solution for your project. Be it ReactJS, plain Javascript or some custom framework - we will make sure that the front layer of your application has state of the art code that works and looks good to everyone. </p>
          </Row>
        </Col>
        <Col sm={12} md={4}>
          <Row><h2><FaCogs /> Backend</h2></Row>
          <Row>
            <p>Your product needs to be available at all times. It needs to serve you and your clients. This is why we do not limit ourselves to any technology. Cloud based, self hosted, monolith or microservice based - you are going to be in good hands! </p>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './assets/styles/app.scss';
import Home from "./components/Home";

let Routes = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={Home} />
    </Switch>
  </Router>
);

let App = () => (
  <Routes />
);

document.addEventListener("DOMContentLoaded", () => {
  render(
    <App />,

    document.getElementById('root')
  );
});

import React from "react";
import {Badge, Col, Container, Jumbotron, Row, Toast} from "react-bootstrap";
import {AiOutlineCloud, DiGo, DiJavascript, DiRuby, FiServer} from "react-icons/all";

import jasiekImage from "../assets/images/jasiek.png";
import LeadForm from "./LeadForm";

export default class JumboHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showJasiek: false
    };

    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.setState({
      showJasiek: true,
    });
  }

  render() {
    return (
      <Jumbotron fluid className={'header-jumbotron'}>
        <Container>
          <Row className={'text-light justify-content-sm-center text-center'}>
            <Col xs={{ span: 4, offset: 4 }} sm={12} md={{span: 6, offset: 0}}>
              <Row>
                <h2><Badge variant={'danger'}><DiRuby /> Ruby</Badge></h2>
              </Row>
              <Row>
                <h2><Badge variant={'info'}><DiGo /> Golang</Badge></h2>
              </Row>
              <Row>
                <h2><Badge variant={'warning'}><DiJavascript/> Javascript</Badge></h2>
              </Row>
              <Row>
                <h2><FiServer /> Self-hosted...</h2>
              </Row>
              <Row><h2>or cloud based <AiOutlineCloud/> </h2></Row>
            </Col>
            <Col sm={12} md={6}>
              <h1>You have found the perfect <span className={'developer-text'}>>DEVELOPERS_</span> to deliver your project.</h1>
            </Col>
          </Row>

          <Row className={'text-light'}>
            <p>Whether you look for freelancers, tiny gigs, ongoing development or maintenance - <strong>Electric Sheep Studio</strong> will do the job.</p>
          </Row>

          <Row>
            <Col xs={{ span: 8, offset: 2 }} sm={12} md={{ span: 4, offset: 0 }}>
              <Toast show={this.state.showJasiek} className={'jasiek-saying-hi'}>
                <Toast.Header closeButton={false}>
                  <img src={ jasiekImage } height={'30px'} className="rounded mr-2" alt="" />
                  <strong className="mr-auto">Jasiek</strong>
                  <small>Just now</small>
                </Toast.Header>
                <Toast.Body>
                  <p>Hi there!</p>
                  <p>Let us know what your project is about - we will provide estimation and our implementation plan!</p>
                  <p>You can either use the form or reach out straight to <a href="mailto:us@electricsheepstud.io">us@electrichseepstud.io</a>.</p>
                </Toast.Body>
              </Toast>
            </Col>
            <Col xs={12} sm={12} md={8}>
              <LeadForm />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    )
  }
}
import React from "react";

import Navigation from './Navigation';
import JumboHeader from './JumboHeader';
import Design from './Design';
import Services from './Services';
import Footer from './Footer';

export default class Home extends React.Component {
  render() {
    return (
      <>
        <Navigation />

        <JumboHeader />

        <Services />

        <Design />

        <Footer />
      </>
    )
  }
}

